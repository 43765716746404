import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import HistoryIcon from "@mui/icons-material/History";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TollIcon from "@mui/icons-material/Toll";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

export const DRAWER_LIST: Array<{
  icon: React.ReactNode;
  name: string;
  url: string;
}> = [
  {
    icon: <PersonIcon />,
    name: "Users",
    url: "/users",
  },
  {
    icon: <PeopleIcon />,
    name: "Benefits",
    url: "/benefits",
  },
  {
    icon: <HistoryIcon />,
    name: "Web history",
    url: "/web-history",
  },
  {
    icon: <TollIcon />,
    name: "Coupons",
    url: "/coupons?tab=all-coupons",
  },
  {
    icon: <AnalyticsOutlinedIcon />,
    name: "Analytics",
    url: "/analytics?tab=app",
  },
  {
    icon: <SearchIcon />,
    name: "Words",
    url: "/words",
  },
  {
    icon: <PhonelinkSetupIcon />,
    name: "Services",
    url: "/services",
  },
  {
    icon: <AdminPanelSettingsIcon />,
    name: "Settings",
    url: "/settings",
  },
];
