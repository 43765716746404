import { AxiosError } from "axios";
import { setRecoil } from "recoil-nexus";
import { snackbarState } from "../store/snackbar.atom";


const urls = ["auth/login", "users/me/generate-qr-code"];

export const handleRequestMessage = (data: AxiosError | any) => {
  if (data instanceof AxiosError) {
    let message = "Something went wrong, please try again!";
    if (data.response) {
      message = data.response?.data?.message;
    }
    setRecoil(snackbarState, {
      text: message,
      variant: "error",
    } as const);
  } else {
    const isUnsnackable = urls.some((url) => {
      return data.config?.url.includes(url);
    });
    if (isUnsnackable) {
      return;
    }
    if (!(data?.config?.method === "get")) {
      setRecoil(snackbarState, {
        text: "Operation successfully done!",
        variant: "success",
      } as const);
    }
  }
};
