import "./assets/styles/base.scss";
import { ThemeProvider } from "@mui/material";
import { theme } from "./assets/mui";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routing/Routing";
import { SnackbarProvider } from "notistack";
import { AppContextProvider } from "./context/AppContext";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <div className={"app"}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <AppContextProvider>
                <Routing />
              </AppContextProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
